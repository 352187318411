module.exports = {
  siteTitle: 'Robin Schiel – Psychotherapie', // <title>
  manifestName: 'Spectral',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#CA6b18',
  manifestThemeColor: '#CA6b18',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-spectral/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Robin Schiel',
  subHeading: 'Psychotherapie',
  // social
  socialLinks: [
  ],
};
